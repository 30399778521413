<template>
  <RenderInstructionRuleCollection
    ref="collection"
    :service="service"
    :edit-fields="additionalFields"
    :columns="columns"
  >
    <template #addSlot>
      <ProcessingRuleForm
        :add="true"
        :edit="false"
        :duplicate="false"
        :organization="collection.selectedOrganization"
        @closedialogs="closeDialogs"
      />
    </template>
    <template #editSlot>
      <ProcessingRuleForm
        :add="false"
        :edit="true"
        :duplicate="false"
        :selected-rule="collection.selectedItems[0].data"
        @closedialogs="closeDialogs"
      />
    </template>
    <template #duplicateSlot>
      <ProcessingRuleForm
        :add="false"
        :edit="false"
        :duplicate="true"
        :selected-rule="collection.selectedItems[0].data"
        @closedialogs="closeDialogs"
      />
    </template>
  </RenderInstructionRuleCollection>
</template>

<script>
import ProcessingService from "@/services/ProcessingService"
import { ref, reactive } from "vue"

export default {
  setup() {
    const service = new ProcessingService("processings")
    const additionalFields = reactive({
      alias: "",
      parameterFile: "",
      setting: 0,
      brightness: 0.0,
      contrast: 0.0,
      latitude: 0.0,
      edge: 0.0,
      noise: 0.0,
    })
    const columns = reactive([
      { field: "alias", header: "Alias", width: "6rem" },
      { field: "parameterFile", header: "Parameter File", width: "20rem" },
      { field: "setting", header: "Index", width: "4rem" },
      { field: "brightness", header: "Brightness", width: "6rem", icon: "Brightness6" },
      { field: "contrast", header: "Contrast", width: "5rem", icon: "ContrastCircle" },
      { field: "latitude", header: "Latitude", width: "5rem", icon: "ArrowExpandHorizontal" },
      { field: "noise", header: "Noise", width: "5rem", icon: "blur" },
      { field: "edge", header: "Edge", width: "5rem", icon: "BorderLeftVariant" },
    ])
    const collection = ref()

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.reload()
    }

    return {
      service,
      additionalFields,
      columns,
      closeDialogs,
      collection,
    }
  },
}
</script>
